var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "company-profile" }, [
      _c("h2", { staticClass: "detail-header" }, [
        _vm._v("\n      Company Profile\n    "),
      ]),
      _c(
        "div",
        { staticClass: "row mt-4" },
        _vm._l(_vm.companyProfileMainInfo, function (details, name) {
          return _c(
            "div",
            {
              key: name,
              staticClass: "col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3",
            },
            [
              _c("detail-card", {
                attrs: { "attribute-name": name, "attribute-value": details },
              }),
            ],
            1
          )
        }),
        0
      ),
      _vm.viewAll
        ? _c(
            "div",
            { staticClass: "row" },
            _vm._l(_vm.allCompanyDetails, function (details, name) {
              return _c(
                "div",
                {
                  key: name,
                  staticClass: "col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3",
                },
                [
                  _c("detail-card", {
                    staticClass: "mt-3",
                    attrs: {
                      "attribute-name": name,
                      "attribute-value": details,
                    },
                  }),
                ],
                1
              )
            }),
            0
          )
        : _vm._e(),
      _c("div", { staticClass: "row view-all-container" }, [
        _c(
          "div",
          {
            staticClass: "view-all-btn col-12 offset-4",
            on: { click: _vm.toggleViewAll },
          },
          [
            _c("p", { staticClass: "toggle-view-button mt-1" }, [
              _vm._v(
                "\n          " +
                  _vm._s(
                    _vm.viewAll
                      ? "Hide all details"
                      : "View all of company details"
                  ) +
                  "\n        "
              ),
            ]),
            _c("fa-icon", {
              staticClass: "ml-2",
              attrs: { icon: ["fas", "chevron-right"] },
            }),
          ],
          1
        ),
      ]),
    ]),
    _vm.companyContactInfo
      ? _c("div", { staticClass: "company-profile" }, [
          _c("h2", { staticClass: "detail-header" }, [
            _vm._v("\n      Contact Information\n    "),
          ]),
          _c(
            "div",
            { staticClass: "row mt-4" },
            _vm._l(_vm.companyContactInfo, function (details, name) {
              return _c(
                "div",
                {
                  key: name,
                  staticClass: "col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3",
                },
                [
                  _vm.isAddress(name)
                    ? _c("detail-address-card", {
                        staticClass: "mt-3",
                        attrs: { "attribute-name": name, detail: details },
                      })
                    : _vm._e(),
                  !_vm.isAddress(name)
                    ? _c("detail-card", {
                        staticClass: "mt-3",
                        attrs: {
                          "attribute-name": name,
                          "attribute-value": details,
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            }),
            0
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }