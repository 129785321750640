<template>
  <div>
    <div class="company-profile">
      <h2 class="detail-header">
        Company Profile
      </h2>
      <div class="row mt-4">
        <div
          v-for="(details, name) in companyProfileMainInfo"
          :key="name"
          class="col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3"
        >
          <detail-card :attribute-name="name" :attribute-value="details" />
        </div>
      </div>
      <div v-if="viewAll" class="row">
        <div
          v-for="(details, name) in allCompanyDetails"
          :key="name"
          class="col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3"
        >
          <detail-card :attribute-name="name" :attribute-value="details" class="mt-3" />
        </div>
      </div>
      <div class="row view-all-container">
        <div
          class="view-all-btn col-12 offset-4"
          @click="toggleViewAll"
        >
          <p class="toggle-view-button mt-1">
            {{ viewAll ? 'Hide all details' : 'View all of company details' }}
          </p>
          <fa-icon :icon="['fas', 'chevron-right']" class="ml-2" />
        </div>
      </div>
    </div>
    <div v-if="companyContactInfo" class="company-profile">
      <h2 class="detail-header">
        Contact Information
      </h2>
      <div class="row mt-4">
        <div
          v-for="(details, name) in companyContactInfo"
          :key="name"
          class="col-xl-3 col-lg-3 col-md-6 col-sm-12 mb-3"
        >
          <detail-address-card v-if="isAddress(name)" :attribute-name="name" :detail="details" class="mt-3" />
          <detail-card v-if="!isAddress(name)" :attribute-name="name" :attribute-value="details" class="mt-3" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'CompanyProfile',
  components: {
    DetailCard:        () => import('@/components/CompanyDetails/DetailCard.vue'),
    DetailAddressCard: () => import('@/components/CompanyDetails/DetailAddressCard.vue'),
  },
  data() {
    return {
      viewAll: false,
      contactInfoKeys: ['company_principal_address', 'company_mailing_address', 'phone_number', 'website', 'email_address'],
      mainInfoKeys: ['company_name', 'entity_type', 'locality', 'entity_id_number', 'management_type', 'registration_file_date', 'management_type', 'ein'],
      companyProfileMainInfo: null,
      companyContactInfo: null,
    }
  },
  computed: {
    ...mapGetters('companyDetails', ['dataMapDetails']),
    allCompanyDetails() {
      if (this.viewAll) {
        return Object.entries(this.dataMapDetails).reduce((details, [key, value]) => {
          if (this.additionalDetail(key) && this.show(key, value)) {
            details[key] = value
          }
          return details
        }, {})
      }
      return false
    },
  },
  mounted() {
    this.setMainInfo()
    this.setContactInfo()
  },
  methods: {
    toggleViewAll() {
      this.viewAll = !this.viewAll
    },
    setMainInfo() {
      this.companyProfileMainInfo = this.mainInfoKeys.reduce((companyProfile, key ) => {
        if (this.dataMapDetails[key]) {
          companyProfile[key] = this.dataMapDetails[key]
        }
        return companyProfile
      }, {})
    },
    setContactInfo() {
      const contactInfo = this.contactInfoKeys.reduce((companyContact, key ) => {
        if (this.dataMapDetails[key]) {
          companyContact[key] = this.dataMapDetails[key]
        }
        return companyContact
      }, {})

      this.companyContactInfo = Object.keys(contactInfo).length === 0 ? null : contactInfo
    },
    additionalDetail(key) {
      return !this.mainInfoKeys.includes(key) && !this.contactInfoKeys.includes(key)
    },
    isAddress(name) {
      const addresses = ['company_mailing_address', 'company_principal_address']
      return addresses.includes(name)
    },
    show(key, value) {
      return typeof value === 'string' && value
    },
  },
}
</script>

<style lang="scss" scoped>
.company-profile {
  h2.detail-header {
    font-size: 1.5em;
    font-weight: 700;
    line-height: 1.34;
  }

  .view-all-container {
    justify-content: flex-end;

    .view-all-btn {
      width: 100% !important;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: $ct-ui-primary;

      .toggle-view-btn {
        text-align: right;
      }

      svg {
        margin-bottom: 0.875em;
      }
    }
  }
}
</style>
